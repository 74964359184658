<template>
  <div class="page">
    <div class="header-box">
      <div class="search-box">
        <div>
          <input
            type="text"
            :placeholder="$t('global.keywordMsg')"
            v-model.trim="formInline.title"
            @keydown.enter="getData(1)"
            @blur="aaaa()"
            @focus="showClear = true"
          />
          <div
            v-if="showClear"
            class="clear-btn"
            style="
              display: flex;
              align-items: center;
              background: #ffffff;
              margin-left: -2px;
              padding: 0 5px;
            "
          >
            <i
              class="el-icon-circle-close"
              style="font-size: 26px; color: #999999; cursor: pointer"
              @click="(formInline.title = ''), startSearch()"
            ></i>
          </div>
          <div class="btn" @click="startSearch">
            {{ $t("global.searchText") }}
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-loading="loading">
      <div>
        <div class="myTabs">
          <el-tabs v-model="tabIndex" @tab-click="handleClick">
            <el-tab-pane
              :label="$t(`Tzh['${item.name}']`)"
              v-for="(item, index) in tabData"
              :key="item.id"
              :name="index + ''"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <el-row :gutter="24">
          <el-col :span="14">
            <div class="article-list" v-for="item in pageData" :key="item.id">
              <div class="article-item">
                <div class="title">{{ $t(`Tzh['${item.name}']`) }}</div>
                <el-empty
                  v-if="item.carbonInformationVOList.length < 1"
                  :description="$t('global.emptyResMsg')"
                ></el-empty>
                <ul>
                  <li
                    v-for="node in item.carbonInformationVOList || []"
                    :key="node.id"
                  >
                    <span @click="toView(node)">
                      {{ node.title }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="article-list">
              <div class="article-item">
                <div class="title">{{ $t("Tzh.资讯榜单") }}</div>
                <el-empty
                  v-if="rightList.length < 1"
                  :description="$t('global.emptyResMsg')"
                ></el-empty>
                <ul>
                  <li v-for="item in rightList" :key="item.id">
                    <span @click="toView(item)">
                      {{ item.title }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      formInline: {},
      tabIndex: '0',
      tabData: [],
      loading: true,
      showClear: false,
      pageData: [],
      rightList: []
    }
  },
  created () {
    this.getData(1)
  },
  methods: {
    startSearch () {
      // if (!this.formInline.title) return this.$message.warning(this.$t('global.seartSearchMsg'))
      this.getData(1)
    },
    handleClick ({ index }) {
      this.switchPage(index)
    },
    toView (item) {
      this.$router.push({
        path: '/consult-view',
        query: {
          id: item.id
        }
      })
    },
    aaaa () {
      setTimeout(_ => {
        this.showClear = false
      }, 300)
    },
    spliceList (list = [], num = 2) {
      let resList = []
      let count = 0
      for (let i = 0; i < (list.length / num); i++) {
        resList[i] = list.slice(count, count + num)
        count += num
      }
      return resList
    },
    getData (page) {
      this.loading = true
      api.tzh.queryCarbonInformationList({
        title: this.formInline.title
      }).then(res => {
        this.loading = false
        this.tabData = res.data.carbonInformationCategoryList
        this.rightList = res.data.topCarbonInformationList
        if (this.tabData && this.tabData.length > 0) {
          this.switchPage(this.tabIndex)
        }
      })
    },
    switchPage (index) {
      this.pageData = this.tabData[index].node
    }
  }
}
</script>
<style>
.g-main-box .u-main > .u-body {
  padding: 0 !important;
  background-color: #fff;
  overflow-y: auto;
}
</style>
<style lang="scss" scoped>
.page {
  width: 100%;
  .header-box {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../../../assets/img/tzh/know-header-bg.png") no-repeat
      center/cover;
    .search-box {
      display: flex;
      justify-content: center;
      padding: 32px 0;
      & > div {
        display: flex;
        justify-content: space-between;
        width: 542px;
        height: 48px;
        background: #ffffff;
        border-radius: 3px;
        input {
          outline: none;
          border: none;
          flex: 1;
          text-indent: 12px;
          border: 1px solid #d9d9d9;
          &::-webkit-input-placeholder {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.25);
          }
        }
        .btn {
          text-align: center;
          width: 98px;
          line-height: 48px;
          background: #09a666;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    & > div {
      width: 1100px;
      .myTabs {
        margin-bottom: 25px;
        /deep/.el-tabs {
          .el-tabs__header {
            margin: 0;
          }
          .el-tabs__nav-wrap::after {
            display: none;
          }
          .el-tabs__item {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000;
            &.is-active {
              color: #09a666;
            }
          }
          .el-tabs__active-bar {
            background-color: #09a666;
            border-radius: 6px;
            height: 4px;
          }
        }
      }
      .article-list {
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 700;
          line-height: 20px;
          color: #000000;
          margin-bottom: 20px;
          &::before {
            content: "";
            display: inline-block;
            position: relative;
            margin-right: 8px;
            top: 5px;
            width: 4px;
            height: 22px;
            background: #09a666;
            border-radius: 10px;
          }
        }
        ul {
          li {
            &::before {
              content: "";
              margin-right: 8px;
              display: inline-block;
              position: relative;
              width: 4px;
              height: 4px;
              top: 50%;
              transform: translateY(-100%);
              background: #09a666;
              opacity: 0.2;
            }
            overflow: hidden; //溢出隐藏
            white-space: nowrap; //禁止换行
            text-overflow: ellipsis; //...
            margin-bottom: 16px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            transition: all 0.5s;
            span {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    /* margin: 32px 163px ; */
  }
}
</style>
